<template>
  <div>
    <BreadCrumb :items="breadCrumb" />
    <EditSchoolForm :key="(school ? school.id : 0)" :value="school" :busy="schoolLoading" />
  </div>
</template>

<script>
import BreadCrumb from '@/components/ui/BreadCrumb.vue';
import EditSchoolForm from '@/components/form/EditSchoolForm.vue';
import getSchoolMixin from '@/mixins/getschool';

export default {
  name: 'BackendEditSchool',
  mixins: [getSchoolMixin],
  components: { BreadCrumb, EditSchoolForm },

  data() {
    return {
      school_id: this.$route.params.school_id,
    };
  },

  computed: {
    breadCrumb() {
      return [
        { text: 'School List', to: { name: 'bke-school-list' } },
        { text: this.school_id, to: { name: 'bke-school-view', params: { school_id: this.school_id } } },
        { text: 'Edit', active: true },
      ];
    },
  },

};
</script>
